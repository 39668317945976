import { disableBodyScroll, enableBodyScroll } from '../../js/vendor/bodyScrollLock.es6';

export default () => {
  const storesMapPopup = document.querySelector('.stores-map-modal');
  if (!storesMapPopup) return;

  const closePopup = () => {
    enableBodyScroll(storesMapPopup, { reserveScrollBarGap: true });
  };

  // todo временно, waiting for choices.js
  // todo не работает как хотелось бы с choices.js даже если обработчик появился
  setTimeout(() => {
    const storesMapPopupOpenControls = document.querySelectorAll('.js-stores-map-open');

    storesMapPopupOpenControls.forEach((control) => {
      control.addEventListener('click', () => {
        window.openPopup('stores-map', closePopup);
        window.setModalMapShop(control.getAttribute('data-shop-index'));
        disableBodyScroll(storesMapPopup, { reserveScrollBarGap: true });
      });
    });
  }, 1000);
};
