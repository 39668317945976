export default () => {
  const filterRangeInput = document.querySelector('.filter__input-range');
  if (!filterRangeInput) return;
  const filterRangeLabel = document.querySelector('.filter__label-range');
  const setFilterRangeLabelText = (data) => {
    filterRangeLabel.textContent = `от ${data.from_pretty} ₽ — до ${data.to_pretty} ₽`;
  };

  $('.filter__input-range').ionRangeSlider({
    type: 'double',
    min: 0,
    max: 37000,
    to: 25000,
    postfix: '₽',
    skin: 'round',
    hide_min_max: true,
    hide_from_to: true,
    force_edges: true,
    extra_classes: 'filter__range-item',
    onStart: setFilterRangeLabelText,
    onChange: setFilterRangeLabelText,
    onUpdate: setFilterRangeLabelText,
  });

  const catalogRangeSlider = $('.filter__input-range').data('ionRangeSlider');
  catalogRangeSlider.update();

  let my_range = $('.filter__input-range').data('ionRangeSlider');
  const buttonCheap = document.querySelector('.filter__tag-wrapper--cheap');
  const buttonMidCheap = document.querySelector('.filter__tag-wrapper--mid-cheap');
  const buttonMid = document.querySelector('.filter__tag-wrapper--mid');
  const buttonExpensive = document.querySelector('.filter__tag-wrapper--expensive');
  const filterFound = document.querySelector('.filter__found');

  const tagButtons = document.querySelectorAll('.filter__tag-wrapper');
  for (let i = 0; i < tagButtons.length; i++) {
    tagButtons[i].addEventListener('click', () => {
      if (filterFound.classList.contains('filter__found--closed')) {
        filterFound.classList.remove('filter__found--closed');
      }
    });
  }

  buttonCheap.addEventListener('click', () => {
    my_range.update({
      from: 0,
      to: 10000,
    });
  });

  buttonMidCheap.addEventListener('click', () => {
    my_range.update({
      from: 10000,
      to: 15000,
    });
  });

  buttonMid.addEventListener('click', () => {
    my_range.update({
      from: 15000,
      to: 20000,
    });
  });

  buttonExpensive.addEventListener('click', () => {
    my_range.update({
      from: 20000,
      to: 37000,
    });
  });
};
