export default () => {
  const pageFooter = document.querySelector('.page-footer');
  if (!pageFooter) return;

  const listCategories = pageFooter.querySelector('.page-footer__nav-list--categories');
  const listSections = pageFooter.querySelector('.page-footer__nav-list--sections');
  const listAbout = pageFooter.querySelector('.page-footer__nav-list--about');
  const liSections = listSections.querySelectorAll('li');
  const liAbout = listAbout.querySelectorAll('li');

  function resizeMenu() {
    if (window.matchMedia('(max-width: 767px)').matches) {
      Array.from(liSections).forEach((it) => {
        listCategories.appendChild(it);
      });
      Array.from(liAbout).forEach((it) => {
        listCategories.appendChild(it);
      });
    } else if (window.matchMedia('(min-width: 768px)').matches) {
      Array.from(liSections).forEach((it) => {
        listSections.appendChild(it);
      });
      Array.from(liAbout).forEach((it) => {
        listAbout.appendChild(it);
      });
    }
  }

  resizeMenu();

  function calcHeight() {
    const flh = Math.ceil(listCategories.querySelectorAll('li').length / 1.33) * listCategories.querySelector('li').offsetHeight;
    document.documentElement.style.setProperty('--flh', `${flh}px`);
  }

  calcHeight();

  let timer;
  const observerHeihgt = new MutationObserver(calcHeight);
  const observerHeightOptions = {
    childList: true,
  };

  observerHeihgt.observe(listCategories, observerHeightOptions);

  window.onresize = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      calcHeight();
      resizeMenu();
    }, 50);
  };
};
