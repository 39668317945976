/* global Swiper */

export default () => {
  const manufacturersContainer = document.querySelector('.manufacturers__container');
  if (!manufacturersContainer) return;

  const observer = new IntersectionObserver(((changes) => {
    if (changes[0].isIntersecting) {
      // eslint-disable-next-line no-use-before-define
      initializeManufacturers();
    }
  }), {
    rootMargin: '500px',
  });

  observer.observe(manufacturersContainer);

  function initializeManufacturers() {
    observer.disconnect();

    const swiperManufacturers = new Swiper(manufacturersContainer, {
      direction: 'horizontal',
      loop: true,
      preloadImages: false,
      lazy: true,
      loadPrevNext: true,
      slidesPerView: 'auto',
      watchSlidesVisibility: true,
      slidesPerGroup: 5,
      spaceBetween: 40,
      centredSlides: false,
      breakpoints: {
        768: {
          slidesPerGroup: 4,
          spaceBetween: 63,
        },
        767: {
          slidesPerGroup: 2,
          spaceBetween: 15,
          slidesPerView: 'auto',
        },
      },
      navigation: {
        nextEl: '.manufacturers .slider-navigation__btn--next',
        prevEl: '.manufacturers .slider-navigation__btn--prev',
      },
    });
  }
};
