
export default () => {
  const acc = document.getElementsByClassName('delivery-info__title');
  let i;

  function openPrice(e) {
    e.target.classList.toggle('active');
    const panel = this.nextElementSibling;
    if (panel.classList.contains('active')) {
      panel.classList.remove('active');
    } else {
      panel.classList.add('active');
    }
  }

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener('click', openPrice);
  }
};
