/* global SimpleBar */
/* global ymaps */

// todo в целом почти аналогичен contacts-section.js
export default () => {
  const storesMapModal = document.querySelector('.stores-map-modal');
  if (!storesMapModal) return;

  const icon = {
    defaultUrl: 'img/contacts-section/logo.svg',
    activeUrl: 'img/contacts-section/logo-active.svg',
    defaultSize: [19, 18],
    activeSize: [29, 28],
  };

  const addressCards = document.querySelectorAll('.contacts-card');
  const addressButtons = document.querySelectorAll('.contacts-card__btn');
  const addressMap = document.querySelector('.stores-map__map-wrapper');
  const closeMapBtn = document.querySelector('.stores-map__close');
  const addressCardsContainer = document.querySelector('.stores-map__cards');
  const sectionList = document.querySelector('.stores-map__list');

  if (sectionList) {
    const simpleBar = new SimpleBar(sectionList, {
      autoHide: false,
      forceVisible: true,
    });
    simpleBar.getScrollElement();
  }

  function initYMap() {
    const locations = [
      {
        title: 'Купчино',
        position: [59.8317828, 30.3836239],
      },
      {
        title: 'Ветеранов',
        position: [59.8511505, 30.2164658],
      },
      {
        title: 'Просвещения',
        position: [60.0554911, 30.3241837],
      },
      {
        title: 'Большевиков',
        position: [59.9438337, 30.4739093],
      },
      {
        title: 'Пр. Большевиков',
        position: [60.0112177, 30.2615843],
      },
      {
        title: 'М. Девяткино',
        position: [60.0503413, 30.4430377],
      },
    ];

    const map = new ymaps.Map('stores-map__map', {
      center: [59.9438337, 30.4739093],
      zoom: 10,
      controls: [],
    });

    // Расчет смещения иконки
    function calcIconImageOffset(iconSize) {
      return [-Math.abs(iconSize[0] / 2), -Math.abs(iconSize[1] / 2)];
    }

    const markers = locations.map(element => new ymaps.Placemark(element.position, {}, {
      iconLayout: 'default#image',
      iconImageHref: icon.defaultUrl,
      iconImageSize: icon.defaultSize,
      iconImageOffset: calcIconImageOffset(icon.defaultSize),
    }));

    markers.forEach((marker) => {
      map.geoObjects.add(marker);
    });

    const addMarker = (index) => {
      markers.forEach((marker) => {
        marker.options.set('iconImageHref', icon.defaultUrl);
        marker.options.set('iconImageSize', icon.defaultSize);
        marker.options.set('iconImageOffset', calcIconImageOffset(icon.defaultSize));
      });
      markers[index].options.set('iconImageHref', icon.activeUrl);
      markers[index].options.set('iconImageSize', icon.activeSize);
      markers[index].options.set('iconImageOffset', calcIconImageOffset(icon.activeSize));
      // eslint-disable-next-line no-underscore-dangle
      map.panTo(markers[index].geometry._coordinates);
    };

    addressCards.forEach((addressCard) => {
      if (document.body.clientWidth > 767) {
        addressCard.addEventListener('click', () => {
          addressCards.forEach((card) => {
            card.classList.remove('active');
          });
          addressCard.classList.add('active');
          addMarker(addressCard.getAttribute('data-index'));
        });
      }
    });

    if (document.body.clientWidth < 768) {
      closeMapBtn.addEventListener('click', () => {
        if (addressMap.classList.contains('stores-map__map-wrapper--active')) {
          addressMap.classList.remove('stores-map__map-wrapper--active');
          addressCardsContainer.style.zIndex = '1';
        }
      });
      addressButtons.forEach((addressButton) => {
        addressButton.addEventListener('click', (e) => {
          addressMap.classList.add('stores-map__map-wrapper--active');
          addressCardsContainer.style.zIndex = '-1';
          addMarker(e.target.getAttribute('data-index'));
        });
      });
    }

    window.setModalMapShop = function setModalMapShop(index) {
      // так карта на скрытом элементе
      map.container.fitToViewport();
      const shopCard = document.querySelector(`[data-index="${index}"]`);
      if (shopCard) {
        if (document.body.clientWidth > 767) {
          addressCards.forEach((card) => {
            card.classList.remove('active');
          });
          shopCard.classList.add('active');
        }
        addMarker(shopCard.getAttribute('data-index'));
      } else {
        console.log(`[data-index="${index}"] not found`);
      }
    };
  }

  function yandexMaps() {
    ymaps.ready(initYMap);
  }

  if (document.querySelector('.stores-map__map')) yandexMaps();
};
