import Choices from '../../js/vendor/choices';
import { enableBodyScroll, disableBodyScroll, clearAllBodyScrollLocks } from '../../js/vendor/bodyScrollLock.es6';

  export default () => {
  const Window_Breakpoints = {
    DESKTOP: 1200,
    TABLET: 768,
    MOBILE: 320,
  };

  const header = document.querySelector('.header');
  const headerOpenedClass = 'header--menu-opened';

  if (!header) return;

  const burgerToggle = header.querySelector('.header__toggle');

  const dropdown = header.querySelector('.header__dropdown');

  const mainNavWrap = header.querySelector('.header__main-nav-wrap');
  const mainNav = header.querySelector('.header__main-nav');

  const contactsWrap = header.querySelector('.header__contacts-wrap');
  const contactsBlock = header.querySelector('.header__contacts');

  const goodsWrap = header.querySelector('.header__choose-goods-container');
  const goodsBlock = header.querySelector('.header__choose-goods-wrap');

  const storesWrap = header.querySelector('.header__stores-container');
  const storesBlock = header.querySelector('.header__stores-wrap');

  const gagerWrap = header.querySelector('.header__call-gager');
  const gagerButton = gagerWrap.querySelector('button');

  const adjustDesktop = () => {
    clearAllBodyScrollLocks(dropdown);
    if (!goodsWrap.children.length) goodsWrap.appendChild(goodsBlock);

    if (!storesWrap.children.length) storesWrap.appendChild(storesBlock);

    if (!gagerWrap.children.length) gagerWrap.appendChild(gagerButton);

    if (!mainNavWrap.children.length) mainNavWrap.appendChild(mainNav);

    if (!contactsWrap.children.length) contactsWrap.appendChild(contactsBlock);
  };


  const adjustTablet = () => {
    if (header.classList.contains('header--menu-opened')) {
      disableBodyScroll(header);
    }

    if (!goodsWrap.children.length) goodsWrap.appendChild(goodsBlock);

    if (!storesWrap.children.length) storesWrap.appendChild(storesBlock);

    if (!gagerWrap.children.length) gagerWrap.appendChild(gagerButton);

    const fragment = new DocumentFragment();
    fragment.appendChild(contactsBlock);
    fragment.appendChild(mainNav);
    dropdown.appendChild(fragment);
  };


  const adjustMobile = () => {
    if (header.classList.contains('header--menu-opened')) {
      disableBodyScroll(header);
    }

    const fragment = new DocumentFragment();

    fragment.appendChild(goodsBlock);
    fragment.appendChild(storesBlock);
    fragment.appendChild(gagerButton);
    fragment.appendChild(contactsBlock);
    fragment.appendChild(mainNav);

    dropdown.appendChild(fragment);
  };


  const checkWindowWidth = () => {
    if (window.matchMedia(`(min-width: ${Window_Breakpoints.DESKTOP}px)`).matches) {
      // if (window.innerWidth >= Window_Breakpoints.DESKTOP) {
      return Window_Breakpoints.DESKTOP;
    }
    if (window.matchMedia(`(min-width: ${Window_Breakpoints.TABLET}px)`).matches) { // if (window.innerWidth >= Window_Breakpoints.TABLET) {
      return Window_Breakpoints.TABLET;
    }
    return Window_Breakpoints.MOBILE;
  };


  const adjustDropdownMaxHeight = () => {
    const rect = header.getBoundingClientRect();

    dropdown.style.maxHeight = `calc(100vh - ${rect.bottom}px)`;
  };

  let lastWindowMode = -1;
  const adjustMenu = () => {
    const currentWindowMode = checkWindowWidth();
    if (lastWindowMode !== currentWindowMode) {
      switch (currentWindowMode) {
        case Window_Breakpoints.MOBILE:
          adjustMobile();
          break;
        case Window_Breakpoints.TABLET:
          adjustTablet();
          break;
        default:
          adjustDesktop();
          break;
      }
      lastWindowMode = currentWindowMode;
    }
  };


  const closeMenu = () => {
    header.classList.remove(headerOpenedClass);
    clearAllBodyScrollLocks(dropdown);
  };


  const openMenu = () => {
    adjustDropdownMaxHeight();
    header.classList.add(headerOpenedClass);
    adjustMenu();
    disableBodyScroll(dropdown);
  };


  burgerToggle.addEventListener('click', () => {
    if (header.classList.contains(headerOpenedClass)) {
      closeMenu();
    } else {
      openMenu();
    }
  });


  let resizeTimeout;

  function resizeThrottler() {
    // ignore resize events as long as an actualResizeHandler execution is in the queue
    if (!resizeTimeout) {
      resizeTimeout = setTimeout(() => {
        resizeTimeout = null;
        adjustMenu();
        adjustDropdownMaxHeight();

        // The actualResizeHandler will execute at a rate of 15fps
      }, 10);
    }
  }

  window.addEventListener('resize', resizeThrottler, false);
  adjustMenu();

  /** ****************************************************************** */

  const goods = goodsBlock.querySelector('.header__choose-goods');

  const goodsChoices = new Choices(goods, {
    searchEnabled: false,
    shouldSort: false,
    choices: [
      {
        value: 'doors',
        label: 'Двери',
        selected: true,
        disabled: false,
        customProperties: {
          icon: 'door',
        },
      },
      {
        value: 'ceiling',
        label: 'Потолки',
        selected: false,
        disabled: false,
        customProperties: {
          icon: 'ceiling',
        },
      },
      {
        value: 'curtains',
        label: 'Шторы',
        selected: false,
        disabled: false,
        customProperties: {
          icon: 'curtains',
        },
      },
      {
        value: 'jalousie',
        label: 'Жалюзи',
        selected: false,
        disabled: false,
        customProperties: {
          icon: 'jalousie',
        },
      },
    ],
    callbackOnCreateTemplates(template) {
      return {
        item: (classNames, data) => template(`
            <div class="${classNames.item} ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable}"
            data-item data-id="${data.id}" data-value="${data.value}" ${data.active ? 'aria-selected="true"' : ''} ${data.disabled ? 'aria-disabled="true"' : ''}>
              <img class="choices__item-icon" width="17" height="24" src="img/icon/${data.customProperties.icon}.svg" alt="" />
              <span>${data.label}</span>
              <svg class="choices__icon-arrow" width="8" height="5"><use xlink:href="#arrow-short-down" /></svg>
            </div>
          `),
        choice: (classNames, data) => template(`
            <div class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}
            ${data.selected ? 'choices__item--selected' : ''}" data-select-text="${this.config.itemSelectText}"
            data-choice ${data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'}
            data-id="${data.id}" data-value="${data.value}" ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}>
              <img class="choices__item-icon" width="17" height="24" src="img/icon/${data.customProperties.icon}.svg" alt="" />
              ${data.label}
            </div>
          `),
      };
    },
  });

  /** ****************************************************************** */

  const stores = storesBlock.querySelector('.header__stores');

  const storesChoices = new Choices(stores, {
    searchEnabled: false,
    shouldSort: false,
    choices: [
      {
        value: 'doors',
        label: 'Девяткино',
        selected: true,
        disabled: false,
        customProperties: {
          address: 'пл. Привокзальная, 1А,',
          addressRefined: 'ТК «Мечта», 2 этаж',
          index: '5',
        },
      },
      {
        value: 'ceiling',
        label: 'Большевиков (Ладожская)',
        selected: false,
        disabled: false,
        customProperties: {
          address: 'пр. Косыгина, 17/1',
          index: '3',
        },
      },
      {
        value: 'curtains',
        label: 'Просвещения',
        selected: false,
        disabled: false,
        customProperties: {
          address: 'пр. Просвещения, 30/1',
          index: '2',
        },
      },
      {
        value: 'jalousie',
        label: 'Ветеранов',
        selected: false,
        disabled: false,
        customProperties: {
          address: 'пр. Маршала Жукова, 41',
          index: '1',
        },
      },
      {
        value: 'jalousie',
        label: 'Купчино',
        selected: false,
        disabled: false,
        customProperties: {
          address: 'ул. Ярослава Гашека, 4/1',
          index: '0',
        },
      },
      {
        value: 'jalousie',
        label: 'Комендантский',
        selected: false,
        disabled: false,
        customProperties: {
          address: 'ул. Уточкина, 5',
          index: '4',
        },
      },
    ],
    callbackOnCreateTemplates(template) {
      return {
        item: (classNames, data) => template(`
            <div class="${classNames.item} ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable}"
            data-item data-id="${data.id}" data-value="${data.value}" ${data.active ? 'aria-selected="true"' : ''} ${data.disabled ? 'aria-disabled="true"' : ''}>
              <img class="choices__icon-marker" width="17" height="24" src="img/icon/marker.svg" alt="" />
              <span class="choices__text">в Санкт-Петербурге</span>
              <span class="choices__title">6 магазинов</span>
              <svg class="choices__icon-arrow" width="8" height="5"><use xlink:href="#arrow-short-down" /></svg>
            </div>
          `),
        choice: (classNames, data) => template(`
            <div class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}"
            data-select-text="${this.config.itemSelectText}" data-choice ${data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'}
            data-id="${data.id}" data-value="${data.value}" ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}>
              <svg class="choices__item-icon" width="14" height="12"><use xlink:href="#subway" /></svg>
              <div>
                <span class="choices__subway">${data.label}</span>
                <span class="choices__adress">${data.customProperties.address}</span>
                ${data.customProperties.addressRefined ? `<span class="choices__adress-refined">${data.customProperties.addressRefined}</span>` : ''}
              </div>
              <a class="choices__item-link link link--dotted js-stores-map-open" data-shop-index="${data.customProperties.index}">Показать на карте</a>
            </div>
          `),
      };
    },
  });

  /** ************************************************************ */

  const CATALOG_MODE_CLASS = 'header__dropdown--catalog';
  const SUB_CATALOG_MODE_CLASS = 'header__dropdown--sub-catalog';
  const ACTIVE_CATALOG_LINK_CLASS = 'nav-catalog__item--active';

  const navCatalog = header.querySelector('.main-nav__item--catalog .main-nav__link');

  navCatalog.addEventListener('click', () => {
    if (dropdown.classList.contains(CATALOG_MODE_CLASS)) {
      dropdown.classList.remove(CATALOG_MODE_CLASS);
    } else {
      dropdown.classList.add(CATALOG_MODE_CLASS);
    }
  });

  const navCatalogLinks = header.querySelectorAll('.nav-catalog__link');

  navCatalogLinks.forEach((element) => {
    element.addEventListener('click', (e) => {
      const parent = e.target.parentElement;

      if (parent.classList.contains(ACTIVE_CATALOG_LINK_CLASS)) {
        // надо схлопнуть секцию
        parent.classList.remove(ACTIVE_CATALOG_LINK_CLASS);
      } else {
        // надо найти открытую и закрыть, и затем открыть текущую
        const currentOpened = header.querySelector(`.${ACTIVE_CATALOG_LINK_CLASS}`);
        if (currentOpened) {
          currentOpened.classList.remove(ACTIVE_CATALOG_LINK_CLASS);
        }

        parent.classList.add(ACTIVE_CATALOG_LINK_CLASS);
      }

      if (dropdown.classList.contains(SUB_CATALOG_MODE_CLASS)) {
        const currentOpened = header.querySelector(`.${ACTIVE_CATALOG_LINK_CLASS}`);
        if (!currentOpened) {
          dropdown.classList.remove(SUB_CATALOG_MODE_CLASS);
        }
      } else {
        dropdown.classList.add(SUB_CATALOG_MODE_CLASS);
      }
    });
  });
};
