/* global Swiper */

export default () => {
  const features = document.querySelector('.features-section');
  if (!features) return;

  const featuresWrapper = document.querySelector('.features-section__wrapper');
  const title = document.querySelector('.js-title');
  let timer;

  if (features && document.body.clientWidth < 768) {
    features.insertAdjacentElement('afterbegin', title);
  }

  // window.addEventListener('resize', () => {
  //   clearTimeout(timer);
  //   timer = setTimeout(() => {
  //     if (document.body.clientWidth <= 768) {
  //       features.insertAdjacentElement('afterbegin', title);
  //     }
  //     if (document.body.clientWidth > 768) {
  //       featuresWrapper.insertAdjacentElement('afterbegin', title);
  //     }
  //   }, 300);
  // });

  const container = document.querySelector('.js-container');
  if (!container) return;

  const wrapper = document.querySelector('.js-wrapper');
  const slides = document.querySelectorAll('.js-slide');
  const widthActive = 768;
  let slider;

  function sliderInit() {
    container.classList.add('swiper-container');
    wrapper.classList.add('swiper-wrapper');
    slides.forEach((slide) => {
      slide.classList.add('swiper-slide');
    });

    slider = new Swiper(container, {
      spaceBetween: 20,
      loop: true,
      slidesPerView: 1,
      navigation: {
        nextEl: '.features-section__btn--next',
        prevEl: '.features-section__btn--prev',
      },

      pagination: {
        el: '.features-section__pagination',
        type: 'bullets',
        bulletElement: 'span',
        bulletClass: 'features-section__bullet',
        bulletActiveClass: 'features-section__bullet--active',
        clickable: true,
      },
    });
  }

  if (document.body.clientWidth < widthActive - 1) sliderInit();

  function sliderDestroy() {
    slider.destroy();
    container.classList.remove('swiper-container');
    wrapper.classList.remove('swiper-wrapper');
    slides.forEach((slide) => {
      slide.classList.remove('swiper-slide');
    });
  }

  function sliderReInit() {
    if (document.body.clientWidth < widthActive - 1) {
      if (typeof slider === 'undefined') {
        sliderInit();
        return;
      }
      if (slider.destroyed) {
        sliderInit();
        return;
      }
    }
    if (document.body.clientWidth > widthActive && typeof slider !== 'undefined' && !slider.destroyed) {
      sliderDestroy();
    }
  }

  window.addEventListener('resize', () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      sliderReInit();
      if (document.body.clientWidth < 768) {
        features.insertAdjacentElement('afterbegin', title);
      } else {
        featuresWrapper.insertAdjacentElement('afterbegin', title);
      }
    }, 300);
  });
};
