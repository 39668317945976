import { disableBodyScroll, enableBodyScroll } from '../../js/vendor/bodyScrollLock.es6';

export default () => {
  const requestAcceptedPopap = document.querySelector('.request-accepted-modal');
  if (!requestAcceptedPopap) return;

  const requestAcceptedPopapOpenControl = document.querySelector('.js-request-accepted-open');

  const closePopup = () => {
    enableBodyScroll(requestAcceptedPopap, { reserveScrollBarGap: true });
  };

  requestAcceptedPopapOpenControl.addEventListener('click', () => {
    window.openPopup('request-accepted', closePopup);
    disableBodyScroll(requestAcceptedPopap, { reserveScrollBarGap: true });
  });
};
