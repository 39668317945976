/* global Swiper */

export default () => {
  const doorsCardSlider = document.querySelector('.doors-card-slider');
  if (!doorsCardSlider) return;

  const thumbnailContainer = document.querySelector('.doors-card-slider__thumbnail-container');
  const previewContainer = document.querySelector('.doors-card-slider__preview-container');

  const swiperThumbnail = new Swiper(thumbnailContainer, {
    direction: 'vertical',
    slidesPerView: 4,
    spaceBetween: 16,
    width: 400,
    freeMode: true,
    preloadImages: false,
    lazy: true,
    loadPrevNext: true,
    loadPrevNextAmount: 4,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    breakpoints: {
      1199: {
        direction: 'horizontal',
        width: 313,
        slidesPerView: 4,
        spaceBetween: 12,
      },
    },
    on: {
      beforeResize() {
        let requiredDirection = 'horizontal';
        if (window.matchMedia('(min-width: 1200px)').matches) {
          requiredDirection = 'vertical';
        }

        if (requiredDirection !== swiperThumbnail.params.direction) {
          swiperThumbnail.changeDirection(requiredDirection);
        }
      },
    },
  });

  const swiperPreview = new Swiper(previewContainer, {
    direction: 'horizontal',
    width: 430,
    preloadImages: false,
    lazy: true,
    loop: true,
    loadPrevNext: true,
    loadPrevNextAmount: 4,
    breakpoints: {
      1199: {
        width: 344,
      },
      767: {
        width: 314,
      },
    },
    thumbs: {
      swiper: swiperThumbnail,
      slideThumbActiveClass: 'doors-card-slider__thumbnail-item--active',
    },
    navigation: {
      nextEl: '.doors-card-slider .slider-navigation__btn--next',
      prevEl: '.doors-card-slider .slider-navigation__btn--prev',
    },
  });

  // const fullScreenButton = doorsCardSlider.querySelector('.doors-card-slider__button');
  // const overlay = doorsCardSlider.querySelector('.doors-card-slider__overlay');

  // fullScreenButton.addEventListener('click', ()=> {
  //   doorsCardSlider.classList.add('doors-card-slider--fullscreen');
  //   const elem = fullScreenButton.parentNode;
  //   const img = elem.querySelector('.swiper-slide-active img');

  //   var newImg = img.cloneNode(true);
  //   overlay.appendChild(newImg);
  //   document.addEventListener('keydown', onOverlayKeydown);
  // });

  // var onOverlayKeydown = (evt) => {
  //   if (evt.key === 'Escape') {
  //     evt.preventDefault();
  //     closeOverlay();
  //   }
  // };

  // let closeOverlay = () => {
  //   doorsCardSlider.classList.remove('doors-card-slider--fullscreen');
  //   while (overlay.firstChild) {
  //     overlay.removeChild(overlay.firstChild);
  //   }
  //   document.removeEventListener('keydown', onOverlayKeydown);
  // };

  // overlay.addEventListener('click', (evt)=> {
  //   if (evt.target == overlay) {
  //     closeOverlay();
  //   }
  // });
};
