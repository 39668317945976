/* global Swiper */

export default () => {
  const similarContainer = document.querySelector('.similar__container');

  if (similarContainer) {
    const swiperSimilar = new Swiper(similarContainer, {
      // Optional parameters
      // wrapperClass: 'manufacturers__list',
      direction: 'horizontal',
      loop: true,
      slidesPerView: 5,
      width: 1168,
      spaceBetween: 50,
      observer: true,
      observeParents: true,
      // width: 1000,
      breakpoints: {
        1200: {
          width: 690,
          spaceBetween: 30,
          slidesPerView: 3,
        },
        767: {
          width: 284,
          spaceBetween: 0,
          slidesPerView: 2,
        },
      },
      // // Navigation arrows
      navigation: {
        nextEl: '.similar .slider-navigation__btn--next',
        prevEl: '.similar .slider-navigation__btn--prev',
      },
      pagination: {
        el: '.similar .slider-navigation__pagination',
        type: 'bullets',
        bulletElement: 'span',
        bulletClass: 'slider-navigation__bullet',
        bulletActiveClass: 'slider-navigation__bullet--active',
        clickable: true,
      },
      // on: {
      //   beforeResize() {
      //     if (window.innerWidth > 767) {
      //       swiperSimilar.slides.css('width', '');
      //     }
      //   },
      // },
    });
  }
};
