import {enableBodyScroll, disableBodyScroll} from '../../js/vendor/bodyScrollLock.es6';

export default () => {
  const reviewPopup = document.querySelector('.review-modal');
  if (!reviewPopup) return;

  const closePopup = () => {
    enableBodyScroll(reviewPopup, { reserveScrollBarGap: true });
  };

  setTimeout(function () {
    var openControls = document.querySelectorAll('.js-review-open');
    openControls.forEach(function (control) {
      control.addEventListener('click', function () {
        window.openPopup('review', closePopup);
      });
    });
  }, 1000);

  const lightbox = $('.review-modal__img-link').simpleLightbox(
    {
      nav: false,
      showCounter: false,
    },
  );
};
