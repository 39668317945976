import { disableBodyScroll, enableBodyScroll } from '../../js/vendor/bodyScrollLock.es6';

export default () => {
  const popup = document.querySelector('.door-modal');
  if (!popup) return;

  const doorPopapOpenControls = document.querySelectorAll('.js-door-open');

  const closePopup = () => {
    enableBodyScroll(popup, { reserveScrollBarGap: true });
  };

  doorPopapOpenControls.forEach((item) => {
    item.addEventListener('click', () => {
      window.openPopup('door', closePopup);
      disableBodyScroll(popup, { reserveScrollBarGap: true });
    });
  });
};
