/* global Swiper */
import Flickity from 'flickity';
import Flickityhash from 'flickity-hash';

export default () => {
  const promoContainer = document.querySelector('.promo__list');

  if (promoContainer) {
    const flkty = new Flickity(promoContainer, {
      // options
      cellAlign: 'left',
      pageDots: false,
      contain: true,
      prevNextButtons: false,
      hash: true,
    });
    const prevButton = document.querySelector('.slider-navigation__btn--prev');

    prevButton.addEventListener('click', () => {
      flkty.previous();
    });

    const nextButton = document.querySelector('.slider-navigation__btn--next');
    nextButton.addEventListener('click', () => {
      flkty.next();
    });

    const promoSlides = document.querySelectorAll('.promo__item');
    const slidePaginationContainer = document.querySelectorAll('.promo__pagination-link');
    let slideNumber;

    promoSlides.forEach((promoSlide, index) => {
      if (promoSlide.classList.contains('is-selected')) {
        slideNumber = index;
      }
    });

    slidePaginationContainer[slideNumber].classList.add('active');


    flkty.on('change', (index) => {

      const slideNumber = index + 1;
      const sliderPagination = document.querySelector(`.slide-${index + 1}`);

      slidePaginationContainer.forEach((item) => {
        item.classList.remove('active');
      });

      sliderPagination.parentNode.classList.add('active');
    });

    // const swiperPromo = new Swiper(promoContainer, {
    //   loop: true,
    //   autoplay: {
    //     delay: 3000,
    //   },
    //   // Navigation arrows
    //   navigation: {
    //     nextEl: '.promo .slider-navigation__btn--next',
    //     prevEl: '.promo .slider-navigation__btn--prev',
    //   },
    // });

    // promoContainer.addEventListener('mouseenter', () => {
    //   swiperPromo.autoplay.stop();
    // });
    //
    // promoContainer.addEventListener('mouseleave', () => {
    //   swiperPromo.autoplay.start();
    // });
    //


    for (let i = 0; i < promoSlides.length; i++) {
      const currentTime = new Date();
      setPromoCountdown(promoSlides[i], new Date(currentTime.getTime() + 1000 * 60 * 60 * 24 * 2)); // +2 суток
    }
  }
};

export const setPromoCountdown = (promoSlideElement, endDate) => {
  if (!promoSlideElement || !endDate) return;

  const millisecondsInDay = 86400000;
  const millisecondsInHour = 3600000;
  const millisecondsInMinute = 60000;

  const daysElement = promoSlideElement.querySelector('.promo__remaining-value--days');
  const hoursElement = promoSlideElement.querySelector('.promo__remaining-value--hours');
  const minutesElement = promoSlideElement.querySelector('.promo__remaining-value--minutes');
  const secondsElement = promoSlideElement.querySelector('.promo__remaining-value--seconds');

  const countdown = window.setInterval(() => {
    let remainingMilliseconds = endDate - Date.now();

    if (remainingMilliseconds < 0) {
      clearTimeout(countdown);
      return;
    }

    const remaingDays = Math.floor(remainingMilliseconds / millisecondsInDay);
    daysElement.textContent = (`00${remaingDays}`).slice(-2);
    remainingMilliseconds -= remaingDays * millisecondsInDay;

    const remaingHours = Math.floor(remainingMilliseconds / millisecondsInHour);
    hoursElement.textContent = (`00${remaingHours}`).slice(-2);
    remainingMilliseconds -= remaingHours * millisecondsInHour;

    const remaingMinutes = Math.floor(remainingMilliseconds / millisecondsInMinute);
    minutesElement.textContent = (`00${remaingMinutes}`).slice(-2);
    remainingMilliseconds -= remaingMinutes * millisecondsInMinute;

    const remaingSeconds = Math.floor(remainingMilliseconds / 1000);
    secondsElement.textContent = (`00${remaingSeconds}`).slice(-2);
  }, 1000);
};
