/* global Swiper */

export default () => {
  const container = document.querySelector('.js-stats-container');
  if (!container) return;

  const wrapper = document.querySelector('.js-stats-wrapper');
  const slides = document.querySelectorAll('.js-stats-slide');
  let timer;
  let slider;

  function sliderInit() {
    container.classList.add('swiper-container');
    wrapper.classList.add('swiper-wrapper');
    slides.forEach((slide) => {
      slide.classList.add('swiper-slide');
    });

    slider = new Swiper(container, {
      slidesPerView: 1,
      loop: true,
      spaceBetween: 18,
      pagination: {
        el: '.js-stats-pagination',
        type: 'bullets',
        bulletElement: 'span',
        bulletClass: 'pagination-bullet',
        bulletActiveClass: 'pagination-bullet--active',
        clickable: true,
      },
    });
  }

  if (document.body.clientWidth < 768) sliderInit();

  // region Реинициализация слайдера при изменении размеров окна
  function sliderDestroy() {
    slider.destroy();
    container.classList.remove('swiper-container');
    wrapper.classList.remove('swiper-wrapper');
    slides.forEach((slide) => {
      slide.classList.remove('swiper-slide');
    });
  }

  function sliderReInit() {
    if (document.body.clientWidth < 768) {
      if (typeof slider === 'undefined') {
        sliderInit();
        return;
      }
      if (slider.destroyed) {
        sliderInit();
        return;
      }
    }
    if (document.body.clientWidth > 767 && typeof slider !== 'undefined' && !slider.destroyed) {
      sliderDestroy();
    }
  }

  window.addEventListener('resize', () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      sliderReInit();
    }, 300);
  });
  // endregion
};
