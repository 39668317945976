/* eslint-disable */
import './general/polyfills';
import './vendor/intersection-observer';
import utils from './general/utils';
import lazyload from './general/lazyload';
/* eslint-enable */

// region Blocks
import header from '../blocks/header/header';
import modal from '../blocks/modal/modal';
import costCalculationModal from '../blocks/cost-calculation-modal/cost-calculation-modal';
import buyModal from '../blocks/buy-modal/buy-modal';
import requestAcceptedModal from '../blocks/request-accepted-modal/request-accepted-modal';
import doorHandleModal from '../blocks/door-handle-modal/door-handle-modal';
import callRequestModal from '../blocks/call-request-modal/call-request-modal';
import reviewModal from '../blocks/review-modal/review-modal';
import advantages from '../blocks/advantages/advantages';
import orderReceivedModal from '../blocks/order-received-modal/order-received-modal';
import doorsCard from '../blocks/doors-card/doors-card';
import storesMap from '../blocks/stores-map/stores-map';
import storesMapModal from '../blocks/stores-map-modal/stores-map-modal';
import gagerModal from '../blocks/gager-modal/gager-modal';
import videoModal from '../blocks/video-modal/video-modal';
import pageFooter from '../blocks/page-footer/page-footer';
import doorsSection from '../blocks/doors-section/doors-section';
import filter from '../blocks/filter/filter';
import filterRange from '../blocks/filter/filter-range';
import catalogSeo from '../blocks/catalog-seo/catalog-seo';
import featuresSection from '../blocks/features-section/features-section';
import offers from '../blocks/offers/offers';
import chooseForm from '../blocks/choose-form/choose-form';
import reviewsSection from '../blocks/reviews-section/reviews-section';
import consultation from '../blocks/consultation/consultation';
import contactsSection from '../blocks/contacts-section/contacts-section';
import manufacturers from '../blocks/manufacturers/manufacturers';
import promo from '../blocks/promo/promo';
import portfolio from '../blocks/portfolio/portfolio';
import seo from '../blocks/seo/seo';
import cardDetails from '../blocks/card-details/card-details';
import interior from '../blocks/interior/interior';
import similar from '../blocks/similar/similar';
import formInputs from '../blocks/form-input/form-input';
import formTextareas from '../blocks/form-textarea/form-textarea';
import feedbackForm from '../blocks/feedback-form/feedback-form';
import doorsCardSlider from '../blocks/doors-card-slider/doors-card-slider';
import doubleSlider from '../blocks/double-slider/double-slider';
import aboutUs from '../blocks/about-us/about-us';
import handle from '../blocks/handle/handle';
import sale from '../blocks/sale/sale';
import features from '../blocks/features/features';
import pagination from '../blocks/page-pagination/page-pagination';
import specialists from '../blocks/specialists/specialists';
import ourWork from '../blocks/our-work/our-work';
import characteristics from '../blocks/characteristics/characteristics';
import cardData from '../blocks/card-data/card-data';
import doorModal from '../blocks/door-modal/door-modal';
import catalogProducts from '../blocks/catalog-products/catalog-products';
import delivery from '../blocks/delivery-content/delivery-content';
import reviewCard from '../blocks/review-card/review-card';
import headerMini from '../blocks/header-mini/header-mini';


// endregion

utils();
lazyload();
header();
pageFooter();
doorsSection();
filter();
filterRange();
catalogSeo();
featuresSection();
offers();
reviewsSection();
consultation();
chooseForm();
contactsSection();
manufacturers();
promo();
portfolio();
seo();
cardDetails();
interior();
similar();
formInputs();
formTextareas();
feedbackForm();
modal();
costCalculationModal();
buyModal();
requestAcceptedModal();
doorHandleModal();
callRequestModal();
advantages();
orderReceivedModal();
storesMap();
doorsCard();
storesMapModal();
gagerModal();
videoModal();
doorsCardSlider();
doubleSlider();
aboutUs();
handle();
sale();
features();
pagination();
specialists();
ourWork();
characteristics();
cardData();
doorModal();
catalogProducts();
delivery();
reviewModal();
reviewCard();
headerMini();
