/* global Swiper */
/* global Cleave */
import { disableBodyScroll, enableBodyScroll } from '../../js/vendor/bodyScrollLock.es6';

const PHONE_NUMBER_LENGHT = 24;

export default () => {
  const gagerPopup = document.querySelector('.gager-modal');
  if (!gagerPopup) return;

  const gagerFeatuers = document.querySelector('.gager-modal__features-wrapper');
  const gagerPopapOpenControl = document.querySelector('.js-gager-open');
  const gagerForm = gagerPopup.querySelector('.gager-modal__form');
  const phoneInput = gagerPopup.querySelector('.js-gager-phone');
  const warning = gagerPopup.querySelector('.js-gager-phone + svg');
  const inputFields = gagerPopup.querySelectorAll('.gager-modal__client-data-value');

  const cleave = new Cleave(phoneInput, {
    numericOnly: true,
    prefix: '+7',
    noImmediatePrefix: true,
    delimiters: [' ( ', ' ) ', ' - ', ' - '],
    blocks: [2, 3, 3, 2, 2],
  });

  const setFocus = (element) => {
    element.focus();
  };

  const getValidationErrors = (inputValue) => {
    let errorMessage = '';
    const errors = {
      isShort: false,
      isEmpty: false,
    };

    const errorNameToErrorMessage = {
      isShort: 'Неверный номер',
      isEmpty: 'Введите номер телефона',
    };

    errors.isShort = errors.isShort || (inputValue.length < PHONE_NUMBER_LENGHT);
    errors.isEmpty = errors.isEmpty || (inputValue.length === 0);

    Object.keys(errors).forEach((errorName) => {
      if (errors[errorName]) {
        errorMessage = errorNameToErrorMessage[errorName];
      }
    });

    return errorMessage;
  };

  const checkPhoneValidation = () => {
    const validationErrors = getValidationErrors(phoneInput.value);

    if (validationErrors) {
      phoneInput.classList.add('input--error');
      warning.style.opacity = 1;
    } else {
      phoneInput.classList.remove('input--error');
      warning.style.opacity = 0;
    }

    phoneInput.setCustomValidity(validationErrors);
  };

  const onPhoneInputChange = () => {
    checkPhoneValidation();
  };

  const onFormSubmit = (evt) => {
    evt.preventDefault();

    if (!phoneInput.value.length) {
      phoneInput.setCustomValidity('Введите номер телефона');
      checkPhoneValidation();
    } else {
      // отправка формы
      console.log('send form ', phoneInput.value);
      closePopup();
    }
  };

  const setGagerPopapListeners = () => {
    gagerForm.addEventListener('submit', onFormSubmit);
    phoneInput.addEventListener('input', onPhoneInputChange);
  };

  const removeGagerPopapListeners = () => {
    gagerForm.removeEventListener('submit', onFormSubmit);
    phoneInput.removeEventListener('input', onPhoneInputChange);
  };

  const clearInputFields = () => {
    inputFields.forEach((inputField) => {
      inputField.value = '';
    });
  };

  const closePopup = () => {
    phoneInput.setCustomValidity('');
    warning.style.opacity = 0;
    phoneInput.classList.remove('input--error');

    clearInputFields();
    removeGagerPopapListeners();
    enableBodyScroll(gagerPopup, { reserveScrollBarGap: true });
  };

  gagerPopapOpenControl.addEventListener('click', () => {
    window.openPopup('gager', closePopup);
    setGagerPopapListeners();
    setFocus(phoneInput);
    disableBodyScroll(gagerPopup, { reserveScrollBarGap: true });
  });

  if (window.matchMedia('(max-width: 767px)').matches) {
    const gagerMobileSlider = {
      wrapperClass: 'gager-modal__features',
      direction: 'horizontal',
      loop: true,
      width: 284,
      slidesPerView: 1,
      centeredSlides: true,

      pagination: {
        el: '.gager-modal__pagination',
        type: 'bullets',
        bulletElement: 'span',
        bulletClass: 'pagination__bullet',
        bulletActiveClass: 'pagination__bullet--active',
        clickable: true,
      },
    };
    const gagerFeatuersSwiper = new Swiper(gagerFeatuers, gagerMobileSlider);
  }
};
