import Simplelightbox from '../../js/vendor/simple-lightbox';
import {enableBodyScroll, disableBodyScroll} from '../../js/vendor/bodyScrollLock.es6';

export default () => {
  try {
    const lightbox = $('.review-card__left-column-link').simpleLightbox(
      {
        nav: false,
        showCounter: false,
      },
    );
  } catch (e) {}


  const setModalData = (sliderItem, reviewModal, sliderItemCount) => {
    reviewModal.classList.add('done');

    const name = sliderItem.querySelector('cite').innerHTML;
    const time = sliderItem.querySelector('time').innerHTML;
    const content = sliderItem.querySelector('.js-full-content').innerHTML;
    const imageSrc = sliderItem.querySelector('.review-card__left-column-link img').src;

    reviewModal.querySelector('.review-modal__wrap').dataset.slideIndex = sliderItemCount;
    reviewModal.querySelector('.review-modal__name').innerHTML = name;
    reviewModal.querySelector('.review-modal__time').innerHTML = time;
    reviewModal.querySelector('.review-modal__text').innerHTML = content;
    reviewModal.querySelector('.review-modal__img').src = imageSrc;

    reviewModal.classList.remove('slide-animation_left', 'slide-animation_right', 'done');
  };

  document.querySelectorAll('.review-card__link').forEach((button) => {
    button.addEventListener('click', () => {
      const reviewModal = document.querySelector('.review-modal');
      const sliderItem = button.closest('.reviews-section__item');
      const sliderItemCount = sliderItem.dataset.swiperSlideIndex;
      reviewModal.querySelector('.review-modal__wrap').dataset.slideIndex = sliderItemCount;

      setModalData(sliderItem, reviewModal, sliderItemCount);

      window.openPopup('review', () => {
        console.log('closet!');
      });
    });
    return false;
  });

  try {
    document.querySelector('.review-modal__arrow--prev').addEventListener('click', (e) => {
      const currentSlideIndex = e.currentTarget.closest('.review-modal').querySelector('.review-modal__wrap').dataset.slideIndex;
      // const currentSlideIndex = e.currentTarget.parentNode;
      const allSliderItemCount = document.querySelectorAll('.reviews-section__item.swiper-slide:not(.swiper-slide-duplicate)').length;
      const prevSlideIndex = Number.parseInt(currentSlideIndex, 10) === 0 ? allSliderItemCount - 1 : Number.parseInt(currentSlideIndex, 10) - 1;
      // console.log(`тек ${currentSlideIndex}`);
      // console.log(`всего ${allSliderItemCount}`);
      // console.log(`пред ${prevSlideIndex}`);

      const reviewModal = document.querySelector('.review-modal');
      const sliderItem = document.querySelector(`.reviews-section__item[data-swiper-slide-index="${prevSlideIndex}"]`);
      // const sliderItemCount = sliderItem.dataset.swiperSlideIndex;

      reviewModal.classList.add('slide-animation_left');
      setTimeout(setModalData, 300, sliderItem, reviewModal, prevSlideIndex);
    });

    document.querySelector('.review-modal__arrow--next').addEventListener('click', (e) => {
      // const currentSlideIndex = e.currentTarget.closest('.review-modal__wrap').dataset.slideIndex;
      const currentSlideIndex = e.currentTarget.closest('.review-modal').querySelector('.review-modal__wrap').dataset.slideIndex;
      const allSliderItemCount = document.querySelectorAll('.reviews-section__item.swiper-slide:not(.swiper-slide-duplicate)').length;
      const nextSlideIndex = currentSlideIndex == allSliderItemCount - 1 ? 0 : Number.parseInt(currentSlideIndex, 10) + 1;
      // console.log(`тек ${currentSlideIndex}`);
      // console.log(`всего ${allSliderItemCount}`);
      // console.log(`пред ${nextSlideIndex}`);

      const reviewModal = document.querySelector('.review-modal');
      const sliderItem = document.querySelector(`.reviews-section__item[data-swiper-slide-index="${nextSlideIndex}"]`);
      // console.log(sliderItem);
      // const sliderItemCount = sliderItem.dataset.swiperSlideIndex;

      reviewModal.classList.add('slide-animation_right');
      setTimeout(setModalData, 300, sliderItem, reviewModal, nextSlideIndex);
    });
  } catch (e) {}



};
