/* global Swiper */

export default () => {
  const offers = document.querySelector('.offers');

  if (offers) {
    if (window.matchMedia('(max-width: 1199px)').matches) {
      const offersSlider = {
        wrapperClass: 'offers__list',
        direction: 'horizontal',
        loop: true,
        slidesPerView: 2,
        width: 700,
        spaceBetween: 20,
        breakpoints: {
          767: {
            width: 284,
            spaceBetween: 0,
            slidesPerView: 'auto',
            centeredSlides: true,

          },
        },
        pagination: {
          el: '.offers .slider-navigation__pagination',
          type: 'bullets',
          bulletElement: 'span',
          bulletClass: 'slider-navigation__bullet',
          bulletActiveClass: 'slider-navigation__bullet--active',
          clickable: true,
        },
        // Navigation arrows
        navigation: {
          nextEl: '.offers .slider-navigation__btn--next',
          prevEl: '.offers .slider-navigation__btn--prev',
        },
      };
      const offersSwiper = new Swiper(offers, offersSlider);
    }
  }
};
