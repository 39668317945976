export default () => {
  const cardData = document.querySelector('.card-data');
  if (!cardData) return;

  const cardDataLink = cardData.querySelector('.card-data__link');
  const cardDataFavorites = cardData.querySelector('.card-data__favorites');

  const onFavoritesClick = (event) => {
    event.preventDefault();
    if (cardData.classList.contains('card-data--favorites')) {
      cardData.classList.remove('card-data--favorites');
      cardDataLink.innerHTML = 'Добавить в избранное';
    } else {
      cardData.classList.add('card-data--favorites');
      cardDataLink.innerHTML = 'Убрать из избранного';
    }
  };

  cardDataLink.addEventListener('click', onFavoritesClick);
  cardDataFavorites.addEventListener('click', onFavoritesClick);
};
