import Choices from '../../js/vendor/choices';

export default () => {
  const catalogProducts = document.querySelector('.catalog-products');
  const productsType = document.querySelector('.catalog-products__type');

  if (catalogProducts) {
    const productsTypeChoices = new Choices(productsType, {
      searchEnabled: false,
      shouldSort: false,
      choices: [
        {
          value: 'popular',
          label: 'Популярные',
          selected: true,
          disabled: false,
          customProperties: {
            icon: 'popular',
          },
        },
        {
          value: 'new',
          label: 'Цена',
          selected: false,
          disabled: false,
          customProperties: {
            icon: 'new',
          },
        },
        {
          value: 'name',
          label: 'Название',
          selected: false,
          disabled: false,
          customProperties: {
            icon: 'name',
          },
        },
      ],
      callbackOnCreateTemplates(template) {
        return {
          item: (classNames, data) => template(`
              <div class="${classNames.item} ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable}" data-item data-id="${data.id}"
               data-value="${data.value}" ${data.active ? 'aria-selected="true"' : ''} ${data.disabled ? 'aria-disabled="true"' : ''}>
                  <span>${data.label}</span>
                  <svg class="choices__icon-arrow"  width="10" height="6"><use xlink:href="#arrow-short-down" /></svg>
                </div>
              `),
          choice: (classNames, data) => template(`
              <div class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}
              ${data.selected ? 'choices__item--selected' : ''}" data-select-text="${this.config.itemSelectText}"
              data-choice ${data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'} data-id="${data.id}" data-value="${data.value}"
              ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}>
                  ${data.label}
                </div>
              `),
        };
      },
    });

    const productsNumber = document.querySelector('.catalog-products__number');

    const productsNumberChoices = new Choices(productsNumber, {
      searchEnabled: false,
      shouldSort: false,
      choices: [
        {
          value: '12',
          label: '12',
          selected: true,
          disabled: false,
          customProperties: {
            icon: '12',
          },
        },
        {
          value: '24',
          classNames: {
            containerInner: 'choices__inner--short',
          },
          label: '24',
          selected: false,
          disabled: false,
          customProperties: {
            icon: '24',
          },
        },
        {
          value: '48',
          label: '48',
          selected: false,
          disabled: false,
          customProperties: {
            icon: '48',
          },
        },
      ],
      callbackOnCreateTemplates(template) {
        return {
          item: (classNames, data) => template(`
              <div class="${classNames.item} ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable}" data-item data-id="${data.id}"
              data-value="${data.value}" ${data.active ? 'aria-selected="true"' : ''} ${data.disabled ? 'aria-disabled="true"' : ''}>
                  <span>${data.label}</span>
                  <svg class="choices__icon-arrow"  width="10" height="6"><use xlink:href="#arrow-short-down" /></svg>
                </div>
              `),
          choice: (classNames, data) => template(`
              <div class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}
              ${data.selected ? 'choices__item--selected' : ''}" data-select-text="${this.config.itemSelectText}"
              data-choice ${data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'} data-id="${data.id}" data-value="${data.value}"
              ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}>
                  ${data.label}
                </div>
              `),
        };
      },
    });
  }
};
