/* global $ */

import Choices from '../../js/vendor/choices';

export default () => {
  const chooseForm = document.querySelector('.choose-form');
  const rangeInput = document.querySelector('.js-range-slider');
  if (!rangeInput) return;

  const rangeLabel = document.querySelector('.choose-form__label-range');
  const setRangeLabelText = (data) => {
    rangeLabel.textContent = `от ${data.from_pretty} ₽ — до ${data.to_pretty} ₽`;
  };

  if (chooseForm) {
    $('.js-range-slider').ionRangeSlider({
      type: 'double',
      min: 0,
      max: 37000,
      to: 25000,
      postfix: '₽',
      skin: 'round',
      hide_min_max: true,
      hide_from_to: true,
      force_edges: true,
      extra_classes: 'choose-form__range',
      onStart: setRangeLabelText,
      onChange: setRangeLabelText,
    });

    const typeDoors = document.querySelector('.choose-form__type');

    const typeDoorsChoices = new Choices(typeDoors, {
      searchEnabled: false,
      shouldSort: false,
      choices: [
        {
          value: 'all',
          label: 'Все',
          selected: true,
          disabled: false,
          customProperties: {
            icon: 'all',
          },
        },
        {
          value: 'interior',
          label: 'Межкомнатные',
          selected: false,
          disabled: false,
          customProperties: {
            icon: 'interior',
          },
        },
        {
          value: 'input',
          label: 'Входные',
          selected: false,
          disabled: false,
          customProperties: {
            icon: 'input',
          },
        },
        {
          value: 'arch',
          label: 'Арки',
          selected: false,
          disabled: false,
          customProperties: {
            icon: 'arch',
          },
        },
      ],
      callbackOnCreateTemplates(template) {
        return {
          item: (classNames, data) => template(`
              <div class="${classNames.item} ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable}" data-item
              data-id="${data.id}" data-value="${data.value}" ${data.active ? 'aria-selected="true"' : ''} ${data.disabled ? 'aria-disabled="true"' : ''}>
                  <span>${data.label}</span>
                  <svg class="choices__icon-arrow"  width="10" height="6"><use xlink:href="#arrow-short-down" /></svg>
                </div>
              `),
          choice: (classNames, data) => template(`
              <div class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}
              ${data.selected ? 'choices__item--selected' : ''}" data-select-text="${this.config.itemSelectText}"
              data-choice ${data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'}
              data-id="${data.id}" data-value="${data.value}" ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}>
                  ${data.label}
                </div>
              `),
        };
      },
    });

    const manufacturer = document.querySelector('.choose-form__manufacturer');
    const manufacturerChoices = new Choices(manufacturer, {
      searchEnabled: false,
      shouldSort: false,
      choices: [
        {
          value: 'all',
          label: 'Все',
          selected: true,
          disabled: false,
        },
        {
          value: 'Uberture',
          label: 'Uberture',
          selected: false,
          disabled: false,
        },
        {
          value: 'VellDoris',
          label: 'VellDoris',
          selected: false,
          disabled: false,
        },
        {
          value: 'ProfilDoors',
          label: 'ProfilDoors',
          selected: false,
          disabled: false,
        },
        {
          value: 'TürenBecker',
          label: 'Türen Becker',
          selected: false,
          disabled: false,
        },
        {
          value: 'Vdf',
          label: 'ВДФ',
          selected: false,
          disabled: false,
        },
        {
          value: 'Voljskie',
          label: 'Волжские двери',
          selected: false,
          disabled: false,
        },
        {
          value: 'Alesta',
          label: 'Алеста',
          selected: false,
          disabled: false,
        },
        {
          value: 'DVERIЯ',
          label: 'DVERI Я',
          selected: false,
          disabled: false,
        },
      ],
      callbackOnCreateTemplates(template) {
        return {
          item: (classNames, data) => template(`
              <div class="${classNames.item} ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable}"
              data-item data-id="${data.id}" data-value="${data.value}" ${data.active ? 'aria-selected="true"' : ''} ${data.disabled ? 'aria-disabled="true"' : ''}>
                  <span>${data.label}</span>
                  <svg class="choices__icon-arrow"  width="10" height="6"><use xlink:href="#arrow-short-down" /></svg>
                </div>
              `),
          choice: (classNames, data) => template(`
              <div class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}
              ${data.selected ? 'choices__item--selected' : ''}" data-select-text="${this.config.itemSelectText}"
              data-choice ${data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'}
              data-id="${data.id}" data-value="${data.value}" ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}>
                  ${data.label}
                </div>
              `),
        };
      },
    });

    const colorDoors = document.querySelector('.choose-form__color');
    const colorDoorsChoices = new Choices(colorDoors, {
      searchEnabled: false,
      shouldSort: false,
      choices: [
        {
          value: 'darkBrown',
          label: 'Темно-коричневый',
          selected: true,
          disabled: false,
        },
        {
          value: 'wenge',
          label: 'Венге',
          selected: false,
          disabled: false,
        },
        {
          value: 'whiteSilk',
          label: 'Белый шелк',
          selected: false,
          disabled: false,
        },
        {
          value: 'lightLinen',
          label: 'Лен светлый',
          selected: false,
          disabled: false,
        },
        {
          value: 'darkLinen',
          label: 'Лен темный',
          selected: false,
          disabled: false,
        },
      ],
      callbackOnCreateTemplates(template) {
        return {
          item: (classNames, data) => template(`
              <div class="${classNames.item} ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable}"
              data-item data-id="${data.id}" data-value="${data.value}" ${data.active ? 'aria-selected="true"' : ''} ${data.disabled ? 'aria-disabled="true"' : ''}>
                  <span>${data.label}</span>
                  <svg class="choices__icon-arrow"  width="10" height="6"><use xlink:href="#arrow-short-down" /></svg>
                </div>
              `),
          choice: (classNames, data) => template(`
              <div class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}
              ${data.selected ? 'choices__item--selected' : ''}" data-select-text="${this.config.itemSelectText}"
              data-choice ${data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'}
              data-id="${data.id}" data-value="${data.value}" ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}>
                ${data.label}
                </div>
              `),
        };
      },
    });
  }
};
