/* global Swiper */
import { disableBodyScroll, enableBodyScroll } from '../../js/vendor/bodyScrollLock.es6';

export default () => {
  const container = document.querySelector('.our-work__photos');
  if (!container) return;

  const wrapper = document.querySelector('.our-work__photos-list');
  const slides = document.querySelectorAll('.our-work__photos-item');
  const widthActive = 1199;
  let timer;
  let slider;

  function sliderInit() {
    container.classList.add('swiper-container');
    wrapper.classList.add('swiper-wrapper');
    slides.forEach((slide) => {
      slide.classList.add('swiper-slide');
    });

    slider = new Swiper(container, {
      width: 539,
      spaceBetween: 34,
      loop: true,
      slidesPerView: 3,
      observer: true,
      observeParents: true,
      breakpoints: {
        // 1199: {
        //   // width: 768,
        //   slidesPerView: 2,
        //   // slidesPerGroup: 4,
        //   spaceBetween: 16,
        //   width: 372,
        // },
        767: {
          width: 284,
          spaceBetween: 20,
          slidesPerView: 2,
        },
      },
      navigation: {
        nextEl: '.our-work .slider-navigation__btn--next',
        prevEl: '.our-work .slider-navigation__btn--prev',
        disabledClass: 'disabled',
      },
      pagination: {
        el: '.our-work .slider-navigation__pagination',
        type: 'bullets',
        bulletElement: 'span',
        bulletClass: 'slider-navigation__bullet',
        bulletActiveClass: 'slider-navigation__bullet--active',
        clickable: true,
      },
    });
  }

  if (window.matchMedia(`(max-width: ${widthActive}px)`).matches) sliderInit();

  // region Реинициализация слайдера при изменении размеров окна
  function sliderDestroy() {
    slider.destroy();
    container.classList.remove('swiper-container');
    wrapper.classList.remove('swiper-wrapper');
    slides.forEach((slide) => {
      slide.classList.remove('swiper-slide');
    });
  }

  function sliderReInit() {
    if (window.matchMedia(`(max-width: ${widthActive}px)`).matches) {
      if (typeof slider === 'undefined') {
        sliderInit();
        return;
      }
      if (slider.destroyed) {
        sliderInit();
        return;
      }
    }
    if (!window.matchMedia(`(max-width: ${widthActive}px)`).matches && typeof slider !== 'undefined' && !slider.destroyed) {
      sliderDestroy();
    }
  }

  window.addEventListener('resize', () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      sliderReInit();
    }, 300);
  });
  // endregion

  const root = document.getElementsByTagName('html')[0];

  slides.forEach((slide) => {
    slide.addEventListener('click', () => {
      if (root) {
        root.classList.add('has-lightbox');
      } else {
        root.classList.remove('has-lightbox');
      }
    });
  });


  $('.our-work__item-link').simpleLightbox({
    disableScroll: true,
    captions: false,
    animationSpeed: 100,
    animationSlide: false,
    spinner: false,
  });
};
