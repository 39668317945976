  /* global Swiper */
import { disableBodyScroll, enableBodyScroll } from '../../js/vendor/bodyScrollLock.es6';

export default () => {
  const portfolioContainer = document.querySelector('.portfolio__container');
  if (!portfolioContainer) return;

  const observer = new IntersectionObserver(((changes) => {
    if (changes[0].isIntersecting) {
      // eslint-disable-next-line no-use-before-define
      initializePortfolio();
    }
  }), {
    rootMargin: '500px',
  });

  observer.observe(portfolioContainer);

  function initializePortfolio() {
    observer.disconnect();

    const swiperPortfolio = new Swiper(portfolioContainer, {
      direction: 'horizontal',
      loop: true,
      preloadImages: false,
      lazy: true,
      loadPrevNext: true,
      loadPrevNextAmount: 3,
      watchSlidesVisibility: true,
      slidesPerView: 3,
      spaceBetween: 40,
      breakpoints: {
        1050: {
          slidesPerView: 2,
          loadPrevNextAmount: 2,
          spaceBetween: 20,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
        },
        767: {
          slidesOffsetBefore: 18,
          slidesOffsetAfter: 18,
          spaceBetween: 8,
          slidesPerView: 'auto',
        },
      },
      navigation: {
        nextEl: '.portfolio .slider-navigation__btn--next',
        prevEl: '.portfolio .slider-navigation__btn--prev',
      },
      pagination: {
        el: '.portfolio .slider-navigation__pagination',
        type: 'bullets',
        bulletElement: 'span',
        bulletClass: 'slider-navigation__bullet',
        bulletActiveClass: 'slider-navigation__bullet--active',
        clickable: true,
      },
      on: {
        beforeResize() {
          if (window.innerWidth <= 767) {
            swiperPortfolio.slides.css('width', '');
          }
        },
      },
    });
  }

  $('.portfolio__item-link').simpleLightbox({
    disableScroll: true,
    captions: false,
    animationSpeed: 100,
    animationSlide: false,
    spinner: false,
  });
};
