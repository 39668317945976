import { disableBodyScroll, enableBodyScroll } from '../../js/vendor/bodyScrollLock.es6';

export default () => {
  const videoModal = document.querySelector('.video-modal');
  const openVideoBtn = document.querySelector('.js-vm-open');
  if (!videoModal || !openVideoBtn) return;
  const iframe = videoModal.querySelector('.js-vm-iframe');

  const closePopup = () => {
    enableBodyScroll(videoModal, { reserveScrollBarGap: true });
    iframe.src = openVideoBtn.dataset.iframeSrc;
  };

  openVideoBtn.addEventListener('click', () => {
    window.openPopup('video-modal', closePopup);
    // Берем data-iframe-src и соответствующий src для iframe
    iframe.src = openVideoBtn.dataset.iframeSrc;
    disableBodyScroll(videoModal, { reserveScrollBarGap: true });
  });
};
