/* global SimpleBar */
/* global ymaps */

// todo в целом почти аналогичен stores-map.js
export default () => {
  const contactsSection = document.querySelector('.contacts-section');
  if (!contactsSection) return;

  const observer = new IntersectionObserver(((changes) => {
    if (changes[0].isIntersecting) {
      // eslint-disable-next-line no-use-before-define
      initializeMap();
    }
  }), {
    rootMargin: '500px',
  });

  observer.observe(contactsSection);

  function initializeMap() {
    observer.disconnect();

    const icon = {
      defaultUrl: 'img/contacts-section/logo.svg',
      activeUrl: 'img/contacts-section/logo-active.svg',
      defaultSize: [19, 18],
      activeSize: [29, 28],
    };

    const addressCards = document.querySelectorAll('.contacts-card');
    const addressButtons = document.querySelectorAll('.contacts-card__btn');
    const addressMap = document.querySelector('.contacts-section__map-wrapper');
    const closeMapBtn = document.querySelector('.contacts-section__close');
    const addressCardsContainer = document.querySelector('.contacts-section__cards');
    const sectionList = document.querySelector('.contacts-section__list');

    if (sectionList) {
      const simpleBar = new SimpleBar(sectionList, {
        autoHide: false,
        forceVisible: true,
      });
      simpleBar.getScrollElement();
    }

    function initYMap() {
      const locations = [
        {
          title: 'Купчино',
          position: [59.8317828, 30.3836239],
        },
        {
          title: 'Ветеранов',
          position: [59.8511505, 30.2164658],
        },
        {
          title: 'Просвещения',
          position: [60.0554911, 30.3241837],
        },
        {
          title: 'Пр. Большевиков',
          position: [59.9438337, 30.4739093],
        },
        {
          title: 'Ладожская',
          position: [60.0112177, 30.2615843],
        },
        {
          title: 'М. Девяткино',
          position: [60.0503413, 30.4430377],
        },
      ];

      const map = new ymaps.Map('contacts-section__map', {
        center: [59.9438337, 30.4739093],
        zoom: 10,
        controls: [],
      });

      // Расчет смещения иконки
      function calcIconImageOffset(iconSize) {
        return [-Math.abs(iconSize[0] / 2), -Math.abs(iconSize[1] / 2)];
      }

      const markers = locations.map(element => new ymaps.Placemark(element.position, {}, {
        iconLayout: 'default#image',
        iconImageHref: icon.defaultUrl,
        iconImageSize: icon.defaultSize,
        iconImageOffset: calcIconImageOffset(icon.defaultSize),
      }));

      markers.forEach((marker) => {
        map.geoObjects.add(marker);
      });

      const addMarker = (index) => {
        markers.forEach((marker) => {
          marker.options.set('iconImageHref', icon.defaultUrl);
          marker.options.set('iconImageSize', icon.defaultSize);
          marker.options.set('iconImageOffset', calcIconImageOffset(icon.defaultSize));
        });
        markers[index].options.set('iconImageHref', icon.activeUrl);
        markers[index].options.set('iconImageSize', icon.activeSize);
        markers[index].options.set('iconImageOffset', calcIconImageOffset(icon.activeSize));
        // eslint-disable-next-line no-underscore-dangle
        map.panTo(markers[index].geometry._coordinates);
      };

      addressCards.forEach((addressCard) => {
        if (window.matchMedia('(min-width: 768px)').matches) {
          addressCard.addEventListener('click', () => {
            addressCards.forEach((card) => {
              card.classList.remove('active');
            });
            addressCard.classList.add('active');
            addMarker(addressCard.getAttribute('data-index'));
          });
        }
      });

      if (window.matchMedia('(max-width: 767px)').matches) {
        closeMapBtn.addEventListener('click', () => {
          if (addressMap.classList.contains('contacts-section__map-wrapper--active')) {
            addressMap.classList.remove('contacts-section__map-wrapper--active');
            addressCardsContainer.style.zIndex = '1';
          }
        });
        addressButtons.forEach((addressButton) => {
          addressButton.addEventListener('click', (e) => {
            addressMap.classList.add('contacts-section__map-wrapper--active');
            addressCardsContainer.style.zIndex = '-1';
            // const index = e.currentTarget.getAttribute('data-index');
            addMarker(e.target.getAttribute('data-index'));
          });
        });
      }
    }

    if (document.querySelector('.contacts-section__map')) {
      ymaps.ready(initYMap);
    }
  }
};
