/* global Swiper */

export default () => {
  const photoContainer = document.querySelector('.js-ds-photo-container');
  const textContainer = document.querySelector('.js-ds-text-container');
  if (!photoContainer || !textContainer) return;

  const photoSlider = new Swiper(photoContainer, {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 40,
    loop: true,
    loopedSlides: 2,
    allowTouchMove: false,
    breakpoints: {
      1199: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 10,
      },
    },
  });

  const textSlider = new Swiper(textContainer, {
    slidesPerView: 1,
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    loopedSlides: 2,
    navigation: {
      nextEl: '.js-ds-next',
      prevEl: '.js-ds-prev',
    },
    breakpoints: {
      1199: {
        spaceBetween: 10,
      },
      767: {
        pagination: {
          el: '.js-ds-pagination',
          type: 'bullets',
          bulletElement: 'span',
          bulletClass: 'pagination-bullet',
          bulletActiveClass: 'pagination-bullet--active',
          clickable: true,
        },
      },
    },
  });

  textSlider.controller.control = photoSlider;
};
