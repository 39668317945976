/* global Swiper */

export default () => {
  const specialistsContainer = document.querySelector('.specialists__wrapper');

  if (specialistsContainer) {
    const swiperSpecialists = new Swiper(specialistsContainer, {
      // Optional parameters
      // wrapperClass: 'manufacturers__list',
      direction: 'horizontal',
      loop: true,
      preloadImages: false,
      lazy: true,
      loadPrevNext: true,
      loadPrevNextAmount: 3,
      slidesPerView: 3,
      spaceBetween: 40,
      width: 860,
      observer: true,
      observeParents: true,
      breakpoints: {
        1199: {
          // width: 768,
          slidesPerView: 2,
          loadPrevNextAmount: 3,
          // slidesPerGroup: 4,
          spaceBetween: 16,
          width: 372,
        },
        767: {
          width: 284,
          spaceBetween: 20,
          slidesPerView: 2,
          loadPrevNextAmount: 3,
        },
      },
      // // Navigation arrows
      navigation: {
        nextEl: '.specialists .slider-navigation__btn--next',
        prevEl: '.specialists .slider-navigation__btn--prev',
      },

      pagination: {
        el: '.specialists .slider-navigation__pagination',
        type: 'bullets',
        bulletElement: 'span',
        bulletClass: 'slider-navigation__bullet',
        bulletActiveClass: 'slider-navigation__bullet--active',
        clickable: true,
      },

      on: {
        beforeResize() {
          if (window.matchMedia('(max-width: 767px)').matches) {
            swiperSpecialists.slides.css('width', '');
          }
        },
      },
    });
  }
};
