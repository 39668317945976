import Choices from '../../js/vendor/choices';

export default () => {
  const headerMini = document.querySelector('.header--mini');
  if (headerMini !== null) {
    const storesBlock = headerMini.querySelector('.header__stores-wrap');

    // todo на главной вылезает ошибка и все отваливается из-за ^
    /** ****************************************************************** */
    const stores = storesBlock.querySelector('.header__stores');

    const storesChoices = new Choices(stores, {
      searchEnabled: false,
      shouldSort: false,
      choices: [
        {
          value: 'doors',
          label: 'Девяткино',
          selected: true,
          disabled: false,
          customProperties: {
            address: 'пл. Привокзальная, 1А,',
            addressRefined: 'ТК «Мечта», 2 этаж',
            index: '5',
          },
        },
        {
          value: 'ceiling',
          label: 'Большевиков (Ладожская)',
          selected: false,
          disabled: false,
          customProperties: {
            address: 'пр. Косыгина, 17/1',
            index: '3',
          },
        },
        {
          value: 'curtains',
          label: 'Просвещения',
          selected: false,
          disabled: false,
          customProperties: {
            address: 'пр. Просвещения, 30/1',
            index: '2',
          },
        },
        {
          value: 'jalousie',
          label: 'Ветеранов',
          selected: false,
          disabled: false,
          customProperties: {
            address: 'пр. Маршала Жукова, 41',
            index: '1',
          },
        },
        {
          value: 'jalousie',
          label: 'Купчино',
          selected: false,
          disabled: false,
          customProperties: {
            address: 'ул. Ярослава Гашека, 4/1',
            index: '0',
          },
        },
        {
          value: 'jalousie',
          label: 'Комендантский',
          selected: false,
          disabled: false,
          customProperties: {
            address: 'ул. Уточкина, 5',
            index: '4',
          },
        },
      ],
      callbackOnCreateTemplates(template) {
        return {
          item: (classNames, data) => template(`
            <div class="${classNames.item} ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable}"
            data-item data-id="${data.id}" data-value="${data.value}" ${data.active ? 'aria-selected="true"' : ''} ${data.disabled ? 'aria-disabled="true"' : ''}>
              <img class="choices__icon-marker" width="17" height="24" src="img/icon/marker.svg" alt="" />
              <span class="choices__text">в Санкт-Петербурге</span>
              <span class="choices__title">6 магазинов</span>
              <svg class="choices__icon-arrow" width="8" height="5"><use xlink:href="#arrow-short-down" /></svg>
            </div>
          `),
          choice: (classNames, data) => template(`
            <div class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}"
            data-select-text="${this.config.itemSelectText}" data-choice ${data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'}
            data-id="${data.id}" data-value="${data.value}" ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}>
              <svg class="choices__item-icon" width="14" height="12"><use xlink:href="#subway" /></svg>
              <div>
                <span class="choices__subway">${data.label}</span>
                <span class="choices__adress">${data.customProperties.address}</span>
                ${data.customProperties.addressRefined ? `<span class="choices__adress-refined">${data.customProperties.addressRefined}</span>` : ''}
              </div>
              <a class="choices__item-link link link--dotted js-stores-map-open" data-shop-index="${data.customProperties.index}">Показать на карте</a>
            </div>
          `),
        };
      },
    });
  }

  /** ************************************************************ */
};
