export default () => {
  const paginationButtons = document.querySelectorAll('.page-pagination__elem');

  function addActive(e) {
    const current = document.getElementsByClassName('page-pagination__elem--active');
    current[0].className = current[0].className.replace('page-pagination__elem--active', '');
    e.target.classList.add('page-pagination__elem--active');
  }

  for (let i = 0; i < paginationButtons.length; i++) {
    paginationButtons[i].addEventListener('click', addActive);
  }
};
