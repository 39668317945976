/* global Swiper */

export default () => {
  const reviews = document.querySelector('.reviews-section__wrapper');
  if (!reviews) return;

  const observer = new IntersectionObserver(((changes) => {
    if (changes[0].isIntersecting) {
      // eslint-disable-next-line no-use-before-define
      initializeReviews();
    }
  }), {
    rootMargin: '500px',
  });

  observer.observe(reviews);

  function initializeReviews() {
    observer.disconnect();

    const swiperReviews = new Swiper(reviews, {
      wrapperClass: 'reviews-section__list',
      direction: 'horizontal',
      loop: true,
      slidesPerView: 'auto',
      spaceBetween: 40,
      centeredSlides: true,
      breakpoints: {
        768: {
          centeredSlides: true,
        },
        767: {
          width: 320,
          spaceBetween: 19,
          slidesPerView: 'auto',
          centeredSlides: true,
        },
      },
      navigation: {
        nextEl: '.reviews-section__btn--next',
        prevEl: '.reviews-section__btn--prev',
      },
      pagination: {
        el: '.reviews-section__pagination',
        type: 'bullets',
        bulletElement: 'span',
        bulletClass: 'pagination-bullet',
        bulletActiveClass: 'pagination-bullet--active',
        clickable: true,
      },
    });
  }
};
