export default () => {
  const seoBlock = document.querySelector('.seo');

  if (!seoBlock) return;
  const readMore = seoBlock.querySelector('.seo__button');

  readMore.addEventListener('click', () => {
    seoBlock.classList.add('seo--full-text');
  });
};
