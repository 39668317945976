export default () => {
  const catalogSeo = document.querySelector('.catalog-seo');
  if (!catalogSeo) return;

  const openButton = catalogSeo.querySelector('.catalog-seo__more');
  const seoLeft = catalogSeo.querySelector('.catalog-seo__top ');
  const seoRight = catalogSeo.querySelector('.catalog-seo__right');
  const lastItem = catalogSeo.querySelector('.catalog-seo__item--last');
  const title = catalogSeo.querySelector('.js-seo-title');
  const catalogSeoLeft = catalogSeo.querySelector('.catalog-seo__left');
  const catalogSeoTop = catalogSeo.querySelector('.catalog-seo__top');
  const shortText = catalogSeo.querySelector('.catalog-seo__item--second');


  let timer;

  const openSeo = () => {
    seoLeft.classList.add('catalog-seo__top--opened');
    seoRight.classList.add('catalog-seo__right--opened');
    openButton.style.display = 'none';
    lastItem.style.display = 'block';
    shortText.classList.add('catalog-seo__item--full');
  };

  const onOpenSeoClick = () => {
    openSeo();
  };

  window.addEventListener('resize', () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (document.body.clientWidth < 1200) {
        catalogSeoLeft.insertAdjacentElement('afterbegin', title);
      } else {
        catalogSeoTop.insertAdjacentElement('afterbegin', title);
      }
    }, 300);
  });

  openButton.addEventListener('click', onOpenSeoClick);
};
