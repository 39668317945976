/* global Swiper */

export default () => {
  const doorsSection = document.querySelector('.doors-section');
  if (!doorsSection) return;

  const doorsPopular = document.querySelector('.js-ds-popular');
  const doorsContainerPopular = document.querySelector('.js-ds-popular-container');
  const buttonPopular = document.querySelector('.js-ds-popular-button');

  const doorsSale = document.querySelector('.js-ds-sale');
  const doorsContainerSale = document.querySelector('.js-ds-sale-container');
  const buttonSale = document.querySelector('.js-ds-sale-button');

  const doorsNew = document.querySelector('.js-ds-new');
  const doorsContainerNew = document.querySelector('.js-ds-new-container');
  const buttonNew = document.querySelector('.js-ds-new-button');

  const classVisible = 'visible';
  const classHidden = 'hidden';
  const classActive = 'active';


  // Так как все слайдеры визуально одинаковые
  function DoorsSlider(element, options) {
    this.swiper = new Swiper(element, {
      wrapperClass: options.wrapperClass,
      loop: true,
      slidesPerView: 6,
      slidesPerGroup: 6,
      loopFillGroupWithBlank: true,
      allowTouchMove: false,
      observer: true,
      observeParents: true,
      //slidesOffsetBefore: options.offset ? options.offset : 0,
      // autoHeight: true,
      //slidesOffsetAfter: 8,
      //slidesOffsetBefore: 8,
      breakpoints: { // <= Npx
        1439: {
          slidesPerView: 4,
          slidesPerGroup: 4,
        },
        1199: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        767: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
      },
      navigation: {
        nextEl: options.navigation.nextEl,
        prevEl: options.navigation.prevEl,
      },
      pagination: {
        el: options.pagination.el,
        type: 'bullets',
        bulletElement: 'span',
        bulletClass: 'pagination-bullet',
        bulletActiveClass: 'pagination-bullet--active',
        clickable: true,
      },
    });
  }

  if (doorsContainerPopular) {
    const swiperPopularDoors = new DoorsSlider(doorsContainerPopular, {
      wrapperClass: 'js-ds-popular-list',
      navigation: {
        nextEl: '.js-ds-popular-next',
        prevEl: '.js-ds-popular-prev',
      },
      pagination: {
        el: '.js-ds-popular-pagination',
      },
    });
  }

  if (doorsContainerSale) {
    const swiperSaleDoors = new DoorsSlider(doorsContainerSale, {
      wrapperClass: 'js-ds-sale-list',
      navigation: {
        nextEl: '.js-ds-sale-next',
        prevEl: '.js-ds-sale-prev',
      },
      pagination: {
        el: '.js-ds-sale-pagination',
      },
    });
  }

  if (doorsContainerNew) {
    const swiperNewDoors = new DoorsSlider(doorsContainerNew, {
      wrapperClass: 'js-ds-new-list',
      navigation: {
        nextEl: '.js-ds-new-next',
        prevEl: '.js-ds-new-prev',
      },
      pagination: {
        el: '.js-ds-new-pagination',
      },
    });
  }

  //    {
  //       activateButton: активируемая кнопка,
  //       activateSlider: активируемый слайдер,
  //       hideSliders: массив скрываемых слайдеров,
  //       deactivateButtons: массив деактивируемых кнопок,
  //     }
  function sliderSwitcher(options) {
    options.activateSlider.classList.remove(classHidden);
    options.activateSlider.classList.add(classVisible);
    options.hideSliders.forEach((slider) => {
      slider.classList.remove(classVisible);
      slider.classList.add(classHidden);
    });
    options.deactivateButtons.forEach((button) => {
      button.classList.remove(classActive);
    });
    options.activateButton.classList.add(classActive);
  }

  buttonSale.addEventListener('click', () => {
    sliderSwitcher({
      activateButton: buttonSale,
      activateSlider: doorsSale,
      hideSliders: [doorsNew, doorsPopular],
      deactivateButtons: [buttonNew, buttonPopular],
    });
  });

  buttonNew.addEventListener('click', () => {
    sliderSwitcher({
      activateButton: buttonNew,
      activateSlider: doorsNew,
      hideSliders: [doorsSale, doorsPopular],
      deactivateButtons: [buttonSale, buttonPopular],
    });
  });

  buttonPopular.addEventListener('click', () => {
    sliderSwitcher({
      activateButton: buttonPopular,
      activateSlider: doorsPopular,
      hideSliders: [doorsNew, doorsSale],
      deactivateButtons: [buttonSale, buttonNew],
    });
  });

  const slides = doorsSection.querySelectorAll('.js-ds-popular .doors-section__item.swiper-slide:not(.swiper-slide-duplicate)');
  slides.forEach((item, index) => {
    let slideCount = 6;
    const width = window.innerWidth;
    const slideNumber = index + 1;

    if (width <= 1439) {
      slideCount = 4;
    } else if (width <= 1199) {
      slideCount = 3;
    } else if (width <= 767) {
      slideCount = 2;
    }

    if (slideNumber % slideCount === 0 || slideNumber === slides.length) {
      item.classList.add('left-hover');
    }
  });
};
