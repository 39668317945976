/* global Swiper */

export default () => {
  const container = document.querySelector('.sale');
  if (!container) return;

  const wrapper = document.querySelector('.sale__list');
  const slides = document.querySelectorAll('.sale__item');
  const widthActive = 1199;
  let timer;
  let slider;

  function sliderInit() {
    container.classList.add('swiper-container');
    wrapper.classList.add('swiper-wrapper');
    slides.forEach((slide) => {
      slide.classList.add('swiper-slide');
    });

    slider = new Swiper(container, {
      // spaceBetween: 20,
      loop: true,
      slidesPerView: 1,
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: '.sale .slider-navigation__btn--next',
        prevEl: '.sale .slider-navigation__btn--prev',
        disabledClass: 'disabled',
      },
      pagination: {
        el: '.sale .slider-navigation__pagination',
        type: 'bullets',
        bulletElement: 'span',
        bulletClass: 'slider-navigation__bullet',
        bulletActiveClass: 'slider-navigation__bullet--active',
        clickable: true,
      },
    });
  }

  if (window.matchMedia(`(max-width: ${widthActive}px)`).matches) sliderInit();

  // region Реинициализация слайдера при изменении размеров окна
  function sliderDestroy() {
    slider.destroy();
    container.classList.remove('swiper-container');
    wrapper.classList.remove('swiper-wrapper');
    slides.forEach((slide) => {
      slide.classList.remove('swiper-slide');
    });
  }

  function sliderReInit() {
    if (window.matchMedia(`(max-width: ${widthActive}px)`).matches) {
      if (typeof slider === 'undefined') {
        sliderInit();
        return;
      }
      if (slider.destroyed) {
        sliderInit();
        return;
      }
    }
    if (!window.matchMedia(`(max-width: ${widthActive}px)`).matches && typeof slider !== 'undefined' && !slider.destroyed) {
      sliderDestroy();
    }
  }

  window.addEventListener('resize', () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      sliderReInit();
    }, 300);
  });
  // endregion
};
