/* global Swiper */
import { disableBodyScroll, enableBodyScroll } from '../../js/vendor/bodyScrollLock.es6';

export default () => {
  const orderReceivedPopup = document.querySelector('.order-received-modal');
  if (!orderReceivedPopup) return;

  const orderReceivedPopupOpenControl = document.querySelector('.js-order-received-open');

  const closePopup = () => {
    enableBodyScroll(orderReceivedPopup, { reserveScrollBarGap: true });
  };

  orderReceivedPopupOpenControl.addEventListener('click', () => {
    window.openPopup('order-received', closePopup);
    disableBodyScroll(orderReceivedPopup, { reserveScrollBarGap: true });
  });

  /** ****************************************************** */

  const orderReceivedContainer = document.querySelector('.order-received-modal__container');

  if (orderReceivedContainer) {
    const swiperModal = new Swiper(orderReceivedContainer, {
      direction: 'horizontal',
      loop: true,
      slidesPerView: 3,
      spaceBetween: 0,
      observer: true,
      observeParents: true,
      allowTouchMove: false,
      breakpoints: {
        1200: {
          width: 662,
          spaceBetween: 0,
          slidesPerView: 3,
          allowTouchMove: true,
        },
        767: {
          width: 284,
          spaceBetween: 0,
          slidesPerView: 1,
          allowTouchMove: true,
        },
      },
      navigation: {
        nextEl: '.order-received-modal .slider-navigation__btn--next',
        prevEl: '.order-received-modal .slider-navigation__btn--prev',
      },
      pagination: {
        el: '.order-received-modal .slider-navigation__pagination',
        type: 'bullets',
        bulletElement: 'span',
        bulletClass: 'slider-navigation__bullet',
        bulletActiveClass: 'slider-navigation__bullet--active',
        clickable: true,
      },
      on: {
        beforeResize() {
          if (window.innerWidth > 767) {
            swiperModal.slides.css('width', '');
          }
        },
      },
    });
  }
};
