/* global Swiper */

export default () => {
  const doorCards = document.querySelectorAll('.doors-card');
  if (doorCards.length === 0) return;
  // todo реинициализация слайдера при изменении ширины окна пользователем

  doorCards.forEach((card, index) => {
    const colors = card.querySelectorAll('.doors-card__color');
    const container = card.querySelector('.doors-card__img');
    const nextBtn = card.querySelector('.doors-card__btn--next');
    nextBtn.classList.add(`btn${index}`);
    const prevBtn = card.querySelector('.doors-card__btn--prev');
    prevBtn.classList.add(`btn${index}`);
    const imageSlider = new Swiper(container, {
      wrapperClass: 'doors-card__img-wrapper',
      direction: 'horizontal',
      loop: true,
      spaceBetween: 40,
      slidesPerView: 1,
      autoplay: {
        delay: 2500,
        disableOnInteraction: true,
      },
      observer: true,
      observeParents: true,
      pagination: {
        el: '.doors-card__pagination',
        type: 'bullets',
        bulletElement: 'span',
        bulletClass: 'doors-card__bullet',
        bulletActiveClass: 'doors-card__bullet--active',
        clickable: true,
      },
      navigation: {
        nextEl: `.doors-card__btn--next.btn${index}`,
        prevEl: `.doors-card__btn--prev.btn${index}`,
      },
    });

    imageSlider.autoplay.stop();

    card.addEventListener('mouseenter', () => {
      imageSlider.autoplay.start();
    });

    card.addEventListener('mouseleave', () => {
      imageSlider.autoplay.stop();
    });

    let delActiveColors = function () {
      colors.forEach(function (color) {
        if (color.classList.contains('active')) {
          color.classList.remove('active');
        }
      });
    };

    colors.forEach((color, index) => {
      color.addEventListener('click', () => {
        delActiveColors();
        color.classList.add('active');
        imageSlider.slideTo(index);
      });
    });

    // imageSlider.on('observerUpdate', () => {
    //   imageSlider.update();
    // });
  });

  doorCards.forEach((card) => {
    if (card.classList.contains('doors-card--modal') || card.classList.contains('doors-card--similar') || document.body.clientWidth < 1200) return;
    card.addEventListener('mouseenter', () => {
      // if (card.closest('.swiper-slide:not(.no-hover)')) {
      card.classList.add('doors-card--active');
      // }
    });
    card.addEventListener('mouseleave', () => {
      card.classList.remove('doors-card--active');
    });
  });
};
