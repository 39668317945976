/* global Swiper */

export default () => {
  const container = document.querySelector('.advantages.js-slider');
  if (!container) return;

  const wrapper = container.querySelector('.js-wrapper');
  const slides = container.querySelectorAll('.js-slide');
  const pagination = container.querySelector('.advantages__pagination');
  const widthActive = 768;
  let timer;
  let slider;

  const advantagesMobileSlider = {
    wrapperClass: 'advantages__list',
    direction: 'horizontal',
    loop: true,
    width: 284,
    slidesPerView: 1,
    centeredSlides: true,
    pagination: {
      el: pagination,
      type: 'bullets',
      bulletElement: 'span',
      bulletClass: 'advantages__bullet',
      bulletActiveClass: 'advantages__bullet--active',
      clickable: true,
    },
  };

  function sliderInit() {
    container.classList.add('swiper-container');
    wrapper.classList.add('swiper-wrapper');
    pagination.classList.remove('advantages__pagination--closed');
    slides.forEach((slide) => {
      slide.classList.add('swiper-slide');
    });

    slider = new Swiper(container, advantagesMobileSlider);
  }

  if (document.body.clientWidth < widthActive) sliderInit();

  // region Реинициализация слайдера при изменении размеров окна
  function sliderDestroy() {
    slider.destroy();
    container.classList.remove('swiper-container');
    wrapper.classList.remove('swiper-wrapper');
    pagination.classList.add('advantages__pagination--closed');
    slides.forEach((slide) => {
      slide.classList.remove('swiper-slide');
    });
  }

  function sliderReInit() {
    if (document.body.clientWidth < widthActive) {
      if (typeof slider === 'undefined') {
        sliderInit();
        return;
      }
      if (slider.destroyed) {
        sliderInit();
        return;
      }
    }
    if (document.body.clientWidth >= widthActive && typeof slider !== 'undefined' && !slider.destroyed) {
      sliderDestroy();
    }
  }

  window.addEventListener('resize', () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      sliderReInit();
    }, 300);
  });
  // endregion
};
