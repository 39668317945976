export default () => {
  const cardDetails = document.querySelector('.card-details');

  if (!cardDetails) return;

  const cardDetailsButtons = cardDetails.querySelectorAll('.card-details__button');

  const buttonActiveClass = 'card-details__button--active';
  const contentActiveClass = 'card-details__content--active';

  cardDetailsButtons.forEach((item) => {
    item.addEventListener('click', (evt) => {
      const clickedButton = evt.target;
      const currentActiveButton = cardDetails.querySelector('.' + buttonActiveClass);

      if (clickedButton !== currentActiveButton) {
        currentActiveButton.classList.remove(buttonActiveClass);
        clickedButton.classList.add(buttonActiveClass);

        const currentActiveContent = cardDetails.querySelector('.' + contentActiveClass);
        currentActiveContent.classList.remove(contentActiveClass);

        const contentId = clickedButton.getAttribute('data-section');
        const newActiveContent = cardDetails.querySelector('#' + contentId);
        if (newActiveContent) newActiveContent.classList.add(contentActiveClass);
      }
    });
  });


  const toggleButtons = cardDetails.querySelectorAll('.card-details__tab-toggle');

  toggleButtons.forEach((item) => {
    item.addEventListener('click', (evt) => {
      if (evt.target.parentNode.classList.contains('card-details__content--opened')) {
        evt.target.parentNode.classList.remove('card-details__content--opened');
      } else {
        evt.target.parentNode.classList.add('card-details__content--opened');
      }
    });
  });
};
