/* global Swiper */
export default () => {
  const interior = document.querySelector('.interior');
  if (!interior) return;
  const interiorСontainer = document.querySelector('.interior__container');

  const categoryNames = ['Хайтек', 'Классика', 'Модерн'];

  const swiperInterior = new Swiper(interiorСontainer, {
    direction: 'horizontal',
    loop: true,
    preloadImages: false,
    lazy: true,
    loadPrevNext: true,
    slidesPerView: 'auto',
    slideToClickedSlide: true,
    slideActiveClass: 'interior__item--active',
    watchSlidesVisibility: true,
    breakpoints: {
      1099: {
        slidesPerView: 1,
      },
    },
    pagination: {
      el: '.interior__links',
      clickable: true,
      bulletClass: 'interior__button',
      bulletActiveClass: 'interior__button--active',
      renderBullet: function (index, className) {
        return '<button type="button" class="' + className + '">' + categoryNames[index] + '</button>';
      },
    },
    on: {
      beforeResize() {
        if (window.innerWidth > 1099) {
          swiperInterior.slides.css('width', '');
        }
      },
    },
  });
};
