export default () => {
  const characteristics = document.querySelector('#characteristics');
  const characteristicsList = document.querySelector('.characteristics__list');
  if (!characteristicsList) return;

  const adjustBlockHeight = () => {
    if (!characteristics.classList.contains('card-details__content--active')) return;

    if (!window.matchMedia('(min-width: 1200px)').matches) {
      characteristicsList.style.height = '';
      return;
    }

    const elements = characteristicsList.querySelectorAll('.characteristics__item');

    let fullHeight = 0;
    elements.forEach((element) => {
      fullHeight += element.offsetHeight;
    });
    const halfHeight = fullHeight / 2;

    let blockMaxHeight = 0;
    for (let i = 0; i < elements.length && blockMaxHeight < halfHeight; i++) {
      if (blockMaxHeight + elements[i].offsetHeight > halfHeight) {
        // если высота становится больше половины, то выбрать размер минимальный размер из варианта когда элемент в левой колонке и когда в правой
        blockMaxHeight = Math.min(blockMaxHeight + elements[i].offsetHeight, fullHeight - blockMaxHeight);
      } else {
        blockMaxHeight += elements[i].offsetHeight;
      }
    }

    characteristicsList.style.height = blockMaxHeight + 'px';
  };

  adjustBlockHeight();

  let resizeTimeout;
  function resizeThrottler() {
    // ignore resize events as long as an actualResizeHandler execution is in the queue
    if (!resizeTimeout) {
      resizeTimeout = setTimeout(() => {
        resizeTimeout = null;
        adjustBlockHeight();

        // The actualResizeHandler will execute at a rate of 15fps
      }, 10);
    }
  }

  window.addEventListener('resize', resizeThrottler, false);
};
