/* global $ */
import { disableBodyScroll, enableBodyScroll } from '../../js/vendor/bodyScrollLock.es6';

export default () => {
  const filterWrapper = document.querySelector('.filter-wrapper');
  if (!filterWrapper) return;

  const filterButton = document.querySelector('.catalog-heading__filter');
  const header = document.querySelector('.header');
  const filterReset = document.querySelector('.filter__reset-button');
  const filterFound = document.querySelector('.filter__found');

  const closeFilter = () => {
    header.style.zIndex = '100';
    enableBodyScroll(filterWrapper, { reserveScrollBarGap: true });
  };


  filterButton.addEventListener('click', () => {
    window.openPopup('catalog-filter', closeFilter);
    disableBodyScroll(filterWrapper, { reserveScrollBarGap: true });
  });

  if (window.matchMedia('(min-width: 1200px)').matches) {
    filterWrapper.classList.remove('modal');
    filterWrapper.classList.remove('js-modal');
  }

  $('.js-input').change(() => {
    if ($('.js-input').is(':checked')) {
      $('.filter__found').removeClass('filter__found--closed');
    } else {
      $('.filter__found').addClass('filter__found--closed');
    }
  });

  filterReset.addEventListener('click', () => {
    filterFound.classList.add('filter__found--closed');
  });
};
