/* global SimpleBar */

export default () => {
  const handleList = document.querySelector('.handle__list');

  if (handleList) {
    const simpleBar = new SimpleBar(handleList, {
      autoHide: false,
      forceVisible: true,
    });
    simpleBar.getScrollElement();
  }
};
